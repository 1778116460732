exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-careers-android-developer-index-en-js": () => import("./../../../src/pages/careers/android-developer/index.en.js" /* webpackChunkName: "component---src-pages-careers-android-developer-index-en-js" */),
  "component---src-pages-careers-backend-developer-index-en-js": () => import("./../../../src/pages/careers/backend-developer/index.en.js" /* webpackChunkName: "component---src-pages-careers-backend-developer-index-en-js" */),
  "component---src-pages-careers-frontend-developer-index-en-js": () => import("./../../../src/pages/careers/frontend-developer/index.en.js" /* webpackChunkName: "component---src-pages-careers-frontend-developer-index-en-js" */),
  "component---src-pages-careers-index-en-js": () => import("./../../../src/pages/careers/index.en.js" /* webpackChunkName: "component---src-pages-careers-index-en-js" */),
  "component---src-pages-careers-ios-developer-index-en-js": () => import("./../../../src/pages/careers/ios-developer/index.en.js" /* webpackChunkName: "component---src-pages-careers-ios-developer-index-en-js" */),
  "component---src-pages-careers-software-project-manager-index-en-js": () => import("./../../../src/pages/careers/software-project-manager/index.en.js" /* webpackChunkName: "component---src-pages-careers-software-project-manager-index-en-js" */),
  "component---src-pages-careers-ui-ux-designer-index-en-js": () => import("./../../../src/pages/careers/ui-ux-designer/index.en.js" /* webpackChunkName: "component---src-pages-careers-ui-ux-designer-index-en-js" */),
  "component---src-pages-contact-us-index-en-js": () => import("./../../../src/pages/contact-us/index.en.js" /* webpackChunkName: "component---src-pages-contact-us-index-en-js" */),
  "component---src-pages-hakkimizda-index-tr-js": () => import("./../../../src/pages/hakkimizda/index.tr.js" /* webpackChunkName: "component---src-pages-hakkimizda-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-api-gelistirme-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/api-gelistirme/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-api-gelistirme-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-crossplatform-mobil-uygulama-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/crossplatform-mobil-uygulama/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-crossplatform-mobil-uygulama-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-donanim-gelistirme-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/donanim-gelistirme/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-donanim-gelistirme-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-donanim-urunleri-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/donanim-urunleri/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-donanim-urunleri-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-iot-projeler-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/iot-projeler/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-iot-projeler-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-it-danismanligi-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/it-danismanligi/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-it-danismanligi-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-middleware-yazilim-gelistirme-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/middleware-yazilim-gelistirme/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-middleware-yazilim-gelistirme-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-mis-danismanligi-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/mis-danismanligi/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-mis-danismanligi-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-native-mobil-uygulama-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/native-mobil-uygulama/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-native-mobil-uygulama-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-progressive-web-uygulama-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/progressive-web-uygulama/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-progressive-web-uygulama-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-uygulama-servisleri-gelistirme-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/uygulama-servisleri-gelistirme/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-uygulama-servisleri-gelistirme-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-veritabani-uygulama-gelistirme-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/veritabani-uygulama-gelistirme/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-veritabani-uygulama-gelistirme-index-tr-js" */),
  "component---src-pages-hizmetlerimiz-yazilim-proje-danismanligi-index-tr-js": () => import("./../../../src/pages/hizmetlerimiz/yazilim-proje-danismanligi/index.tr.js" /* webpackChunkName: "component---src-pages-hizmetlerimiz-yazilim-proje-danismanligi-index-tr-js" */),
  "component---src-pages-iletisim-index-tr-js": () => import("./../../../src/pages/iletisim/index.tr.js" /* webpackChunkName: "component---src-pages-iletisim-index-tr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-tr-js": () => import("./../../../src/pages/index.tr.js" /* webpackChunkName: "component---src-pages-index-tr-js" */),
  "component---src-pages-islerimiz-index-tr-js": () => import("./../../../src/pages/islerimiz/index.tr.js" /* webpackChunkName: "component---src-pages-islerimiz-index-tr-js" */),
  "component---src-pages-kariyer-android-developer-index-tr-js": () => import("./../../../src/pages/kariyer/android-developer/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-android-developer-index-tr-js" */),
  "component---src-pages-kariyer-backend-developer-index-tr-js": () => import("./../../../src/pages/kariyer/backend-developer/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-backend-developer-index-tr-js" */),
  "component---src-pages-kariyer-frontend-developer-index-tr-js": () => import("./../../../src/pages/kariyer/frontend-developer/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-frontend-developer-index-tr-js" */),
  "component---src-pages-kariyer-index-tr-js": () => import("./../../../src/pages/kariyer/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-index-tr-js" */),
  "component---src-pages-kariyer-ios-developer-index-tr-js": () => import("./../../../src/pages/kariyer/ios-developer/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-ios-developer-index-tr-js" */),
  "component---src-pages-kariyer-software-project-manager-index-tr-js": () => import("./../../../src/pages/kariyer/software-project-manager/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-software-project-manager-index-tr-js" */),
  "component---src-pages-kariyer-ui-ux-designer-index-tr-js": () => import("./../../../src/pages/kariyer/ui-ux-designer/index.tr.js" /* webpackChunkName: "component---src-pages-kariyer-ui-ux-designer-index-tr-js" */),
  "component---src-pages-meeting-meeting-js": () => import("./../../../src/pages/meeting/meeting.js" /* webpackChunkName: "component---src-pages-meeting-meeting-js" */),
  "component---src-pages-privacy-index-en-js": () => import("./../../../src/pages/privacy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-index-en-js" */),
  "component---src-pages-projects-index-en-js": () => import("./../../../src/pages/projects/index.en.js" /* webpackChunkName: "component---src-pages-projects-index-en-js" */),
  "component---src-pages-services-api-development-index-en-js": () => import("./../../../src/pages/services/api-development/index.en.js" /* webpackChunkName: "component---src-pages-services-api-development-index-en-js" */),
  "component---src-pages-services-application-service-development-index-en-js": () => import("./../../../src/pages/services/application-service-development/index.en.js" /* webpackChunkName: "component---src-pages-services-application-service-development-index-en-js" */),
  "component---src-pages-services-crossplatform-mobile-app-development-index-en-js": () => import("./../../../src/pages/services/crossplatform-mobile-app-development/index.en.js" /* webpackChunkName: "component---src-pages-services-crossplatform-mobile-app-development-index-en-js" */),
  "component---src-pages-services-database-app-development-index-en-js": () => import("./../../../src/pages/services/database-app-development/index.en.js" /* webpackChunkName: "component---src-pages-services-database-app-development-index-en-js" */),
  "component---src-pages-services-hardware-development-index-en-js": () => import("./../../../src/pages/services/hardware-development/index.en.js" /* webpackChunkName: "component---src-pages-services-hardware-development-index-en-js" */),
  "component---src-pages-services-hardware-products-index-en-js": () => import("./../../../src/pages/services/hardware-products/index.en.js" /* webpackChunkName: "component---src-pages-services-hardware-products-index-en-js" */),
  "component---src-pages-services-index-en-js": () => import("./../../../src/pages/services/index.en.js" /* webpackChunkName: "component---src-pages-services-index-en-js" */),
  "component---src-pages-services-iot-projects-index-en-js": () => import("./../../../src/pages/services/iot-projects/index.en.js" /* webpackChunkName: "component---src-pages-services-iot-projects-index-en-js" */),
  "component---src-pages-services-it-consulting-index-en-js": () => import("./../../../src/pages/services/it-consulting/index.en.js" /* webpackChunkName: "component---src-pages-services-it-consulting-index-en-js" */),
  "component---src-pages-services-middleware-software-development-index-en-js": () => import("./../../../src/pages/services/middleware-software-development/index.en.js" /* webpackChunkName: "component---src-pages-services-middleware-software-development-index-en-js" */),
  "component---src-pages-services-mis-consulting-index-en-js": () => import("./../../../src/pages/services/mis-consulting/index.en.js" /* webpackChunkName: "component---src-pages-services-mis-consulting-index-en-js" */),
  "component---src-pages-services-native-mobile-app-development-index-en-js": () => import("./../../../src/pages/services/native-mobile-app-development/index.en.js" /* webpackChunkName: "component---src-pages-services-native-mobile-app-development-index-en-js" */),
  "component---src-pages-services-progressive-web-app-development-index-en-js": () => import("./../../../src/pages/services/progressive-web-app-development/index.en.js" /* webpackChunkName: "component---src-pages-services-progressive-web-app-development-index-en-js" */),
  "component---src-pages-services-software-project-consulting-index-en-js": () => import("./../../../src/pages/services/software-project-consulting/index.en.js" /* webpackChunkName: "component---src-pages-services-software-project-consulting-index-en-js" */)
}

